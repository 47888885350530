// @ts-strict-ignore
import * as icons from "@kiwicom/orbit-components/icons"

import { IssueQuery } from "api/issues"

import {
  BooleanToggleFilter,
  Filter,
  MultiselectFilter,
  SiftableMultiselectFilter,
  TextFilter,
} from "components/generic/break-out-filter"

import {
  ACTIVE_ISSUE_STATUSES,
  INACTIVE_ISSUE_STATUSES,
  IssueSeverity,
  IssueStatus,
  issueSeverityToText,
  issueStatusToText,
} from "types/issue"
import { LocationType } from "types/location"

import { fetchLocationOptions } from "utils/location-utils"
import { getPersonName, useNameShortener } from "utils/name-utils"
import { useGlobalFetcher, useGlobalState } from "utils/state-utils"
import {
  StateStoredInUrl,
  arrayField,
  arrayFieldWithShorthand,
  boolLoader,
  enumLoader,
  scalarField,
} from "utils/url-state"

import { filterAndSortAssignableUsers } from "../shared/loaders"
import { isMaintainableVehicle } from "../shared/utils"

/**
 * This is the set of available Issue filters
 */
export const useIssueFilters = (): Filter<IssueQuery, unknown>[] => {
  const { loggedInPersonUid } = useGlobalState()
  const { data: vehicles } = useGlobalFetcher("vehicles")
  const { data: assignableUsers } = useGlobalFetcher("usersAssignableToIssues")
  const getShortName = useNameShortener("usersAssignableToIssues")
  const locations = fetchLocationOptions([LocationType.DEPOT])

  return [
    new SiftableMultiselectFilter<IssueQuery, number>(
      "vehicleId",
      {
        name: "Vehicle",
        pluralName: "Vehicles",
        icon: icons.Bus,
      },
      vehicles == null
        ? []
        : vehicles.filter(isMaintainableVehicle).map((v) => ({
            id: `${v.id}`,
            label: v.plateNumber,
            value: v.id,
          }))
    ),
    new SiftableMultiselectFilter<IssueQuery, number>(
      "locationId",
      {
        name: "Location",
        pluralName: "Locations",
        icon: icons.Location,
      },
      locations == null
        ? []
        : locations.map((v) => ({
            id: `${v.id}`,
            label: v.name,
            value: v.id,
          }))
    ),
    new MultiselectFilter<IssueQuery, IssueSeverity>(
      "severity",
      {
        name: "Severity",
        pluralName: "Severities",
        icon: icons.Insurance,
      },
      Object.values(IssueSeverity).map((severity: IssueSeverity) => ({
        id: severity,
        label: issueSeverityToText(severity),
        value: severity,
      }))
    ),
    new MultiselectFilter<IssueQuery, IssueStatus>(
      "status",
      {
        name: "Status",
        pluralName: "Statuses",
        icon: icons.Circle,
      },
      // There are only 2 top-level options, "open" and "close", each with a number of children for
      // their respective group of statuses
      [
        {
          id: "open",
          label: "Open",
          value: null,
          children: ACTIVE_ISSUE_STATUSES.map((status: IssueStatus) => ({
            id: status,
            label: issueStatusToText(status),
            value: status,
          })),
        },
        {
          id: "closed",
          label: "Closed",
          value: null,
          children: INACTIVE_ISSUE_STATUSES.map((status: IssueStatus) => ({
            id: status,
            label: issueStatusToText(status),
            value: status,
          })),
        },
      ]
    ),
    new SiftableMultiselectFilter<IssueQuery, string>(
      "assigneeUid",
      {
        name: "Assignee",
        pluralName: "Assignees",
        icon: icons.Passenger,
      },
      assignableUsers == null
        ? []
        : [
            {
              id: "unassigned",
              label: "Unassigned",
              value: "unassigned",
            },
            ...filterAndSortAssignableUsers({
              users: assignableUsers,
              loggedInPersonUid,
            }).map((person) => ({
              id: person.uid,
              label: getPersonName(person),
              shortLabel:
                person.uid == loggedInPersonUid
                  ? "Assigned To Me"
                  : getShortName(person),
              value: person.uid,
            })),
          ]
    ),
    new TextFilter<IssueQuery>("textQuery", {
      name: "Text Search",
      pluralName: "Text Searches",
      icon: icons.Search,
    }),
    new BooleanToggleFilter<IssueQuery>(
      "hideSnoozed",
      {
        name: "Hide Snoozed",
        pluralName: "Hide Snoozed",
        icon: icons.Notification,
      },
      "Hide Snoozed Issues"
    ),
  ]
}

/**
 * This is how we store in the URL hash the `IssueQuery` created by the filters.
 */
export const urlStoreForIssues = new StateStoredInUrl<IssueQuery>(
  [
    scalarField("preset", String),
    arrayField("vehicleId", Number),
    arrayField("severity", enumLoader(IssueSeverity)),
    arrayField("categoryUid", String),
    arrayFieldWithShorthand("status", enumLoader(IssueStatus), {
      "~open~": ACTIVE_ISSUE_STATUSES,
      "~closed~": INACTIVE_ISSUE_STATUSES,
      "~all~": [...ACTIVE_ISSUE_STATUSES, ...INACTIVE_ISSUE_STATUSES],
    }),
    arrayField("assigneeUid", String),
    scalarField("textQuery", String),
    scalarField("limit", Number),
    scalarField("sortColumnId", String),
    scalarField("sortReversed", boolLoader),
    scalarField("hideSnoozed", boolLoader),
  ],
  () => ({})
)
