import React from "react"

import { IssueListView } from "components/maintenance/issues/list"
import { MaintenancePage } from "components/maintenance/layout"

import { MaintenancePermissions } from "types/issue"

const Page = () => (
  <MaintenancePage
    fullScreen
    title="My Issues"
    accessControl={(permissions: MaintenancePermissions) =>
      permissions.maySearchIssues
    }
  >
    <IssueListView selectedTabId="mine" key="mine" />
  </MaintenancePage>
)

export default Page
