// @ts-strict-ignore
import React, { useContext, useState } from "react"

import { navigate } from "gatsby"
import { ThemeContext } from "styled-components"

import {
  Loading,
  Stack,
  Tab,
  TabList,
  Tabs,
  TextLink,
  useMediaQuery,
} from "@kiwicom/orbit-components"

import { IssueQuery } from "api/issues"

import { useBreakOutFilter } from "components/generic/break-out-filter"
import { EmptyStateCardSection } from "components/generic/empty-state-card-section"

import { ACTIVE_ISSUE_STATUSES } from "types/issue"

import { useGlobalState } from "utils/state-utils"

import { IssueTable } from "../table"
import { urlStoreForIssues, useIssueFilters } from "./filters"

interface TabDefinition {
  id: string
  label: string
  presetFilter: Partial<IssueQuery>
  showFilters: boolean
  noResultsMessage?: React.ReactNode
}

export const useTabs = (): TabDefinition[] => {
  const { loggedInPersonUid } = useGlobalState()
  return [
    {
      id: "mine",
      label: "My Open Issues",
      presetFilter: {
        assigneeUid: [loggedInPersonUid],
        status: ACTIVE_ISSUE_STATUSES,
      },
      showFilters: false,
      noResultsMessage: (
        <EmptyStateCardSection
          illustration="Success"
          title={null}
          description="There are no open issues assigned to you"
          padding="XLarge"
        />
      ),
    },
    {
      id: "all",
      label: "All Issues",
      presetFilter: {
        status: ACTIVE_ISSUE_STATUSES,
      },
      showFilters: true,
    },
  ]
}

export const IssueListView = ({ selectedTabId }: { selectedTabId: string }) => {
  const theme = useContext(ThemeContext)
  const allTabs = useTabs()
  const selectedTabIndex = allTabs.findIndex((tab) => tab.id == selectedTabId)
  const selectedTab = allTabs[selectedTabIndex]

  const [issueQuery, setIssueQuery] = selectedTab.showFilters
    ? urlStoreForIssues.useState({ ...selectedTab.presetFilter })
    : useState({ ...selectedTab.presetFilter })
  const [isLoading, setLoading] = useState<boolean>(false)
  const { isTablet } = useMediaQuery()

  const { mainFilterButton, activeFilterDropDowns } = useBreakOutFilter({
    allFilters: useIssueFilters(),
    query: issueQuery,
    setQuery: setIssueQuery,
    dataIsLoading: isLoading,
  })

  const navigateToTab = (newTabIndex) => {
    // Each tab is actually a separate page, it simplifies state management quite a bit
    if (newTabIndex != selectedTabIndex) {
      const newTab = allTabs[newTabIndex]
      navigate(`../${newTab.id}/`)
    }
  }

  return (
    <Stack
      direction="column"
      spacing="medium"
      largeDesktop={{ spacing: "medium" }}
    >
      <Stack direction="row">
        <Stack>
          <Tabs onChange={navigateToTab} defaultSelected={selectedTabIndex}>
            <TabList>
              {allTabs.map(({ id, label }) => (
                <Tab key={id}>
                  {id == selectedTabId ? (
                    label
                  ) : (
                    <TextLink type="secondary" noUnderline href={`../${id}/`}>
                      {label}
                    </TextLink>
                  )}
                </Tab>
              ))}
            </TabList>
          </Tabs>
        </Stack>
        <Stack direction="row" align="center" inline shrink>
          {selectedTab.showFilters ? (
            <>
              {isTablet && activeFilterDropDowns}
              {mainFilterButton}
            </>
          ) : isLoading ? (
            <Loading
              type="searchLoader"
              customSize={parseInt(theme.orbit.spaceLarge)}
            />
          ) : null}
        </Stack>
      </Stack>
      {selectedTab.showFilters &&
        !isTablet &&
        activeFilterDropDowns?.length != 0 && (
          /* On narrow mobile screens, the tabs go on the row below */
          <Stack direction="row" align="center" wrap>
            {activeFilterDropDowns}
          </Stack>
        )}
      <IssueTable
        query={issueQuery}
        isLoading={isLoading}
        setLoading={setLoading}
        noResultsMessage={selectedTab.noResultsMessage}
        isUserFilterable
      />
    </Stack>
  )
}
